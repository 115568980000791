import axios from '@/plugins/axios'

export default {

  async list (data) {
    const params = new URLSearchParams(data).toString()
    return await axios.get(`checklist-items?${params}`)
  },

  async get (id) {
    return await axios.get(`checklist-items/${id}`)
  },

  async insert (data) {
    return await axios.post('checklist-items', data)
  },

  async update (id, data) {
    return await axios.put(`checklist-items/${id}`, data)
  },

  async delete (id) {
    return await axios.delete(`checklist-items/${id}`)
  },

}
