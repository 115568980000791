<template>
  <div>
    <core-page-title />

    <v-container class="mt-3">
      <v-row>
        <v-col>
          <v-btn color="success" class="mr-2" dark @click="openDialog()">
            <v-icon left v-text="'mdi-plus'" />
            Adicionar
          </v-btn>
        </v-col>
      </v-row>

      <v-row class="mt-4">
        <v-col>
          <v-sheet elevation="1">
            <v-data-table
              disable-filtering
              disable-sort
              :server-items-length="count"
              :loading="loading"
              :headers="headers"
              :items="checklistItems"
              :options.sync="pagination"
              :footer-props="{
                'items-per-page-options': [10, 20, 50],
              }"
              @update:options="loadChecklistItems"
            >
              <template v-slot:[`item.actions`]="{ item }" class="text-right">
                <v-btn icon small @click="openDialog(item)">
                  <v-icon v-text="'mdi-pencil'" small />
                </v-btn>
                <v-btn icon small>
                  <v-icon
                    v-text="'mdi-delete'"
                    small
                    @click="clickDeleteItem(item)"
                  />
                </v-btn>
              </template>
            </v-data-table>
          </v-sheet>
        </v-col>
      </v-row>
    </v-container>

    <dialog-checklist-item
      v-model="showDialogChecklistItem"
      :checklist-item-id="editId"
      @save="loadChecklistItems"
    />

    <dialog-delete
      :show="showDialogDelete"
      :loading="loadingDelete"
      @confirm="deleteItem"
      @cancel="showDialogDelete = false"
    />
  </div>
</template>

<script>
import checklistItemsApi from '@/api/checklist-items'

export default {
  components: {
    DialogDelete: () => import('@/components/dialog/DialogDelete'),
    DialogChecklistItem: () =>
      import('@/components/dialog/checklist-items/DialogChecklistItem'),
  },

  data() {
    return {
      loading: false,
      loadingDelete: false,
      showDialogChecklistItem: false,
      showDialogDelete: false,
      count: 0,
      deleteId: null,
      editId: null,
      headers: [
        { text: 'Descrição', value: 'description' },
        { text: '', value: 'actions', align: 'right' },
      ],
      checklistItems: [],
      pagination: {},
    }
  },

  methods: {
    async loadChecklistItems() {
      try {
        this.loading = true

        const response = await checklistItemsApi.list({
          limit: this.pagination.itemsPerPage,
          offset:
            this.pagination.page * this.pagination.itemsPerPage -
            this.pagination.itemsPerPage,
        })

        this.checklistItems = response.data.checklistItems
        this.count = response.data.count
      } catch (e) {
        this.$snackbar.show({ color: 'error', message: this.$apiError._(e) })
      } finally {
        this.loading = false
      }
    },

    openDialog(checklistItem = null) {
      this.editId = checklistItem ? checklistItem.id : null
      this.showDialogChecklistItem = true
    },

    async clickDeleteItem(checklistItem) {
      this.deleteId = checklistItem.id
      this.showDialogDelete = true
    },

    async deleteItem() {
      try {
        this.loadingDelete = true

        await checklistItemsApi.delete(this.deleteId)

        this.showDialogDelete = false
        this.loadChecklistItems()

        this.$snackbar.show({
          color: 'success',
          message: this.$messages._('delete_success'),
        })
      } catch (e) {
        this.$snackbar.show({ color: 'error', message: this.$apiError._(e) })
      } finally {
        this.loadingDelete = false
        this.dialogDelete = false
      }
    },
  },
}
</script>
